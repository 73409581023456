
.card {
  max-height: 100px;
  transition: max-height 0.2s ease-out;
  background: rgb(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border: 1px solid rgb(233, 233, 233);
  -webkit-box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.05);
}
.card:hover {
  max-height: 500px;
  transition: max-height 0.2s ease-in;
}
.uppercase,
.uppercase > .level > .level-item > .button {
  text-transform: uppercase !important;
}
.button {
  width: 30px !important;
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
}
