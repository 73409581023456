
.container {
  padding-top: 5%;
}

.switch-column {
  display: flex;
  align-items: center;
  justify-content: center;
}

.level {
  margin-bottom: 5px;
}

.wordvariant {
  position: relative;
  &:not(:first-child) {
    margin-top: 5px;
  }
}
.accentscard {
  position: absolute;
  z-index: 1000;
  overflow: hidden;
  border-radius: 5px;
  width: 100%;
  top: 40px;
}

strong {
  color: inherit !important;
}

.nested {
  .dropdown-nested {
    display: none;
  }
  &:hover > .dropdown-nested {
    display: block;
  }
}

.no-radius {
  border-radius: 4px;
}
